@import './colors.scss';

body {
  > .header {
    .middle {
      display: flex;
      padding: 72px 128px;
      max-width: 1480px;
      margin: 0 auto;
      justify-content: space-between;
  
      .left {
        .buttons {
          padding: 64px 0;
  
          div {
            display: inline-block;
          }
        }
  
        .checks {
          display: flex;
  
          .check {
            font-size: 20px;
            line-height: 32px;
            margin-right: 25px;
  
            img {
              width: 24px;
              margin-left: 14px;
            }
          }
        }
      }
  
      .right {
        img {
          width: 100%;
          max-width: max-content;
          max-height: max-content;
        }
      }
  
      .left,
      .right {
        width: 50%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 566px;
      }
    }
  
    .bottom {
      display: flex;
      justify-content: space-between;
      padding: 64px 128px;
      max-width: 1480px;
      margin: 0 auto;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      align-items: center;
  
      img {
        margin: 0 16px;
  
        &:first-child {
          margin-left: 0;
        }
  
        &:last-child {
          margin-right: 0;
        }
      }
  
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  
  >.content {
    .block {
      &:first-of-type {
        margin-top: 80px;
      }

      .split {
        display: flex;

        >* {
          width: 50%;
          flex-grow: 1;

          &.vCenter {
            display: flex;
            flex-direction: column;
            justify-content: center;

            picture {
              display: flex;
              justify-content: center;

              > img {
                width: 100%;
                max-width: max-content;
              }
            }
          }
        }
      }

      &.feedback {
        .header {
          display: flex;
          align-items: center;
          gap: 120px;
          margin-bottom: 40px;

          .rating {
            display: flex;
            gap: 16px;
            align-items: center;

            > img {
              width: 105px;
              height: 64px;
            }
          }

          .descriptionWrapper {
            width: 168px;
            display: flex;
            flex-direction: column;
          }

          .ratingResults {
            display: flex;
            gap: 8px;
            align-items: center;

            img { 
              width: 95px;
              height: 16px;
            }
          }

          .description {
            font-size: 16px;
            line-height: 24px;

            a {
              color: $link;
              text-decoration: none;
            }
          }

          .logos {
            flex-grow: 1;
            overflow: hidden;
            flex-wrap: nowrap;
            display: flex;

            > div {
              width: fit-content;
              flex-wrap: nowrap;
              display: flex;
              align-items: center;
              gap: 62px;
              animation: slide 17s linear infinite;
            }

            svg {
              min-width: fit-content;

              &:first-of-type {
                margin-left: 62px;
              }

              path {
                fill: rgba(10, 44, 115, 1);
              }
            }
          }
        }

        .filledLogos {
          flex-grow: 1;
          overflow: hidden;
          flex-wrap: nowrap;
          display: none;
          margin: 80px -60px 0;
          background: $ui_02;
          padding: 32px 0;
          justify-content: center;

          > div {
            width: fit-content;
            flex-wrap: nowrap;
            display: flex;
            align-items: center;
            gap: 48px;
          }

          > div:nth-of-type(2) {
            display: none;
          }

          svg path {
            fill: rgba(10, 44, 115, 1);
          }
        }
      }

      .product {
        background: $neutral_03;
        border-radius: 2px;
        margin: 40px 0;
        color: $ui_01;

        .left>img,
        .right>img {
          width: 100%;
          max-width: max-content;
        }

        >div {
          display: flex;
          flex-direction: column;
          justify-content: center;

          > div {
            margin-top: 16px;
          }
        }

        p {
          margin: 0 0 16px 0;
        }

        &.first {
          padding: 20px 0 20px 80px;
        }

        &.second {
          padding: 20px 80px 20px 0;
          background-color: $neutral_02;

          .more {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
            position: relative;
            flex-direction: column;
            align-items: baseline;

            .os-icons {
              display: flex;
              align-items: center;
              margin-top: 32px;

              .hint .text {
                right: 5px;
              }
            }
          }
        }
      }

      &.customerSuccess {
        .steps {
          display: flex;
          justify-content: space-evenly;
          display: flex;
          justify-content: space-evenly;
          max-width: 560px;
          margin: 80px auto;

          > div {
            cursor: pointer;

            span:first-child {
              border: 1px solid $accent_01;
              display: inline-block;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              align-items: center;
              line-height: 28px;
              margin-right: 8px;
              text-align: center;
            }
  
            &.active > span:first-child {
              background-color: $accent_01;
              color: $ui_01;
            }
          }
        }

        .split {
          display: none;
          gap: 40px;

          &.active {
            display: flex;
          }
        }

        >div {
          margin: 0 auto;
        }

        p {
          margin: 0;
        }
      }

      &.stories {
        margin-bottom: 80px;

        .steps {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          column-gap: 74px;
          row-gap: 24px;
          margin: 40px 0 24px;

          svg path {
            fill: $text_01;
          }

          &.mobile {
            display: none;
          }

          [data-slider-step] {
            cursor: pointer;
            opacity: 0.3;

            &.active {
              opacity: 1;
            }
          }

          img {
            filter: invert(1);
          }
        }

        div.center {
          margin-bottom: 32px;
        }

        [data-slider-item] {
          display: none;

          .author-wrapper {
            align-items: center;

            div {
              display: flex;
            }

            img {
              width: 48px;
              height: 48px;
            }
          }

          &.active {
            display: block;
          }
        }
      }

      &.GDPR {
        color: $ui_01;
        background-color: $neutral_02;
        display: flex;
        align-items: center;
        padding: 40px 60px;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;

          img {
            margin-right: 40px;
            width: 85px;
          }
        }

        .right {
          flex-shrink: 0;
          margin-left: 40px;
        }
      }
    }

    .center {
      text-align: center;
    }

    @import '/packages/site/src/partials/styles/slider.scss';
  }
}

@media only screen and (max-width: 959px) {
  body > .content .block.feedback .header .logos {
    display: none;
  }

  body > .content .block.feedback .filledLogos {
    display: flex;
  }
}

@media only screen and (max-width: 850px) {
  body .header {
    .middle {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      padding: 72px 60px 32px;
  
      .right {
        margin-bottom: 32px;
        width: auto;;
      }
  
      .left {
        width: auto;
  
        .buttons {
          padding: 32px 0;
        }
  
        .checks {
          justify-content: space-around;
  
          .check {
            margin-right: 0;
          }
        }
      }
    }

    .bottom {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  body .content .block.feedback .filledLogos {
    justify-content: unset;

    > div {
      animation: slide 17s linear infinite;
    }

    > div:nth-of-type(2) {
      display: flex;
    }

    svg:first-of-type {
      margin-left: 48px;
    }
  }
}

@media only screen and (max-width: 775px) {
  body {
    .header {
      .middle {
        .left {  
          .buttons {
            flex-direction: column;
            display: flex;
    
            div {
              
              
              &:first-child {
                margin-bottom: 16px;
              }
    
              a {
                width: 100%;
                margin-right: 0;
              }
            }
          }
          
          .checks {
            justify-content: space-around;
        
            .check {
              margin-right: 0;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
    
              img {
                width: 12px;
                margin-left: 7px;
              }
            }
          }
        }
    
        .left,
        .right {
          width: auto;
          max-width: none;
        }
      }

      .bottom {
        padding: 32px 16px;
      }
    }

    .content {
      .block {
        margin: 120px 16px;

        &.feedback .filledLogos {
          margin: 40px -16px 0;
        }

        &.products {
          margin-top: 32px;
          margin-bottom: 0;

          h1 {
            margin-bottom: 12px;
          }

          .product {
            margin: 32px -12px 4px;
            padding: 32px 16px 48px;

            h2 {
              margin-bottom: 16px;
            }

            &:last-child {
              margin-top: 0px;
            }

            &.first div:last-child {
              margin-top: 16px;
            }
          }
      
          h2 {
            display: flex;
            align-items: center;
          }
        }

        &.redLine {
          margin: 64px 16px 48px 0;
        }
      }

      .center {
        text-align: inherit;
      }
    }
  }
}

@media only screen and (max-width: 710px) {
  body {
    .content {
      .block {
        margin: 32px 16px;

        &.products .product {
          &.split {
            flex-direction: column-reverse;
            align-items: center;

            > * {
              width: 100%;
            }
          }

          &.second {
            flex-direction: column;
            .more {
              display: block !important;
              text-align: center;

              .os-icons {
                justify-content: center;
              }
  
              > div:first-child {
                margin-bottom: 32px;
              }
            }
          }
        }

        .button {
          width: 100%;
          text-align: center;
        }

        .bracket::after {
          display: none;
        }

        &.customerSuccess .steps {
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
  
            span:first-child {
              margin-right: 0 !important;
              margin-bottom: 8px;
            }
          }

          &.mobile {
            margin: 32px 0;
          }
        }

        &.stories {
          margin-bottom: 48px;

          .slider-wrapper.mobile {
            .slider-track > [data-slider-item] {
              padding-bottom: 48px;
            }

            .quote-wrapper {
              gap: 16px;
            }
          }
        }

        p {
          font-size: 16px;
          line-height: 24px;
        }

        &.GDPR {
          flex-direction: column;
          padding: 32px 16px 48px;
  
          .left {
            flex-direction: column;
            align-items: center;
            margin-bottom: 32px;
            width: 100%;

            div {
              width: 100%;
            }
  
            img {
              margin: 0 0 32px 0 !important;
            }
          }
  
          .right {
            margin-left: 0 !important;
            width: 100%;
          }
        }
      }

    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}