body > .header .middle {
  justify-content: space-between;
  max-width: 1480px;
  margin: 0 auto;
  padding: 72px 128px;
  display: flex;
}

body > .header .middle .left .buttons {
  padding: 64px 0;
}

body > .header .middle .left .buttons div {
  display: inline-block;
}

body > .header .middle .left .checks {
  display: flex;
}

body > .header .middle .left .checks .check {
  margin-right: 25px;
  font-size: 20px;
  line-height: 32px;
}

body > .header .middle .left .checks .check img {
  width: 24px;
  margin-left: 14px;
}

body > .header .middle .right img {
  width: 100%;
  max-width: max-content;
  max-height: max-content;
}

body > .header .middle .left, body > .header .middle .right {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: 50%;
  max-width: 566px;
  display: flex;
}

body > .header .bottom {
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-content: space-between;
  align-items: center;
  max-width: 1480px;
  margin: 0 auto;
  padding: 64px 128px;
  display: flex;
  overflow: auto;
}

body > .header .bottom img {
  margin: 0 16px;
}

body > .header .bottom img:first-child {
  margin-left: 0;
}

body > .header .bottom img:last-child {
  margin-right: 0;
}

body > .header .bottom::-webkit-scrollbar {
  display: none;
}

body > .content .block:first-of-type {
  margin-top: 80px;
}

body > .content .block .split {
  display: flex;
}

body > .content .block .split > * {
  flex-grow: 1;
  width: 50%;
}

body > .content .block .split > .vCenter {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

body > .content .block .split > .vCenter picture {
  justify-content: center;
  display: flex;
}

body > .content .block .split > .vCenter picture > img {
  width: 100%;
  max-width: max-content;
}

body > .content .block.feedback .header {
  align-items: center;
  gap: 120px;
  margin-bottom: 40px;
  display: flex;
}

body > .content .block.feedback .header .rating {
  align-items: center;
  gap: 16px;
  display: flex;
}

body > .content .block.feedback .header .rating > img {
  width: 105px;
  height: 64px;
}

body > .content .block.feedback .header .descriptionWrapper {
  flex-direction: column;
  width: 168px;
  display: flex;
}

body > .content .block.feedback .header .ratingResults {
  align-items: center;
  gap: 8px;
  display: flex;
}

body > .content .block.feedback .header .ratingResults img {
  width: 95px;
  height: 16px;
}

body > .content .block.feedback .header .description {
  font-size: 16px;
  line-height: 24px;
}

body > .content .block.feedback .header .description a {
  color: #1f29d3;
  text-decoration: none;
}

body > .content .block.feedback .header .logos {
  flex-wrap: nowrap;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

body > .content .block.feedback .header .logos > div {
  flex-wrap: nowrap;
  align-items: center;
  gap: 62px;
  width: fit-content;
  animation: 17s linear infinite slide;
  display: flex;
}

body > .content .block.feedback .header .logos svg {
  min-width: fit-content;
}

body > .content .block.feedback .header .logos svg:first-of-type {
  margin-left: 62px;
}

body > .content .block.feedback .header .logos svg path {
  fill: #0a2c73;
}

body > .content .block.feedback .filledLogos {
  background: #e9eefb;
  flex-wrap: nowrap;
  flex-grow: 1;
  justify-content: center;
  margin: 80px -60px 0;
  padding: 32px 0;
  display: none;
  overflow: hidden;
}

body > .content .block.feedback .filledLogos > div {
  flex-wrap: nowrap;
  align-items: center;
  gap: 48px;
  width: fit-content;
  display: flex;
}

body > .content .block.feedback .filledLogos > div:nth-of-type(2) {
  display: none;
}

body > .content .block.feedback .filledLogos svg path {
  fill: #0a2c73;
}

body > .content .block .product {
  color: #fff;
  background: #0facf2;
  border-radius: 2px;
  margin: 40px 0;
}

body > .content .block .product .left > img, body > .content .block .product .right > img {
  width: 100%;
  max-width: max-content;
}

body > .content .block .product > div {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

body > .content .block .product > div > div {
  margin-top: 16px;
}

body > .content .block .product p {
  margin: 0 0 16px;
}

body > .content .block .product.first {
  padding: 20px 0 20px 80px;
}

body > .content .block .product.second {
  background-color: #3c5dbe;
  padding: 20px 80px 20px 0;
}

body > .content .block .product.second .more {
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 16px;
  display: flex;
  position: relative;
}

body > .content .block .product.second .more .os-icons {
  align-items: center;
  margin-top: 32px;
  display: flex;
}

body > .content .block .product.second .more .os-icons .hint .text {
  right: 5px;
}

body > .content .block.customerSuccess .steps {
  justify-content: space-evenly;
  max-width: 560px;
  margin: 80px auto;
  display: flex;
}

body > .content .block.customerSuccess .steps > div {
  cursor: pointer;
}

body > .content .block.customerSuccess .steps > div span:first-child {
  text-align: center;
  border: 1px solid #e52c34;
  border-radius: 50%;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  line-height: 28px;
  display: inline-block;
}

body > .content .block.customerSuccess .steps > div.active > span:first-child {
  color: #fff;
  background-color: #e52c34;
}

body > .content .block.customerSuccess .split {
  gap: 40px;
  display: none;
}

body > .content .block.customerSuccess .split.active {
  display: flex;
}

body > .content .block.customerSuccess > div {
  margin: 0 auto;
}

body > .content .block.customerSuccess p {
  margin: 0;
}

body > .content .block.stories {
  margin-bottom: 80px;
}

body > .content .block.stories .steps {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 24px 74px;
  margin: 40px 0 24px;
  display: flex;
}

body > .content .block.stories .steps svg path {
  fill: #05174b;
}

body > .content .block.stories .steps.mobile {
  display: none;
}

body > .content .block.stories .steps [data-slider-step] {
  cursor: pointer;
  opacity: .3;
}

body > .content .block.stories .steps [data-slider-step].active {
  opacity: 1;
}

body > .content .block.stories .steps img {
  filter: invert();
}

body > .content .block.stories div.center {
  margin-bottom: 32px;
}

body > .content .block.stories [data-slider-item] {
  display: none;
}

body > .content .block.stories [data-slider-item] .author-wrapper {
  align-items: center;
}

body > .content .block.stories [data-slider-item] .author-wrapper div {
  display: flex;
}

body > .content .block.stories [data-slider-item] .author-wrapper img {
  width: 48px;
  height: 48px;
}

body > .content .block.stories [data-slider-item].active {
  display: block;
}

body > .content .block.GDPR {
  color: #fff;
  background-color: #3c5dbe;
  justify-content: space-between;
  align-items: center;
  padding: 40px 60px;
  display: flex;
}

body > .content .block.GDPR .left {
  align-items: center;
  display: flex;
}

body > .content .block.GDPR .left img {
  width: 85px;
  margin-right: 40px;
}

body > .content .block.GDPR .right {
  flex-shrink: 0;
  margin-left: 40px;
}

body > .content .center {
  text-align: center;
}

body > .content .slider-wrapper.mobile {
  width: calc(100% + 32px);
  position: relative;
  left: -16px;
  overflow: hidden;
}

body > .content .slider-wrapper.mobile .slider-track {
  gap: 8px;
  display: flex;
}

body > .content .slider-wrapper.mobile .slider-track:not(.moving) {
  transition: transform .5s;
}

body > .content .slider-wrapper.mobile .slider-track > [data-slider-item] {
  scroll-snap-align: center;
  background: #e9eefb;
  border-radius: 2px;
  flex-direction: column;
  gap: 32px;
  padding: 32px 16px;
  display: flex;
}

body > .content .slider-wrapper.mobile .slider-track > [data-slider-item] > div {
  width: 100%;
}

@media only screen and (width <= 959px) {
  body > .content .block.feedback .header .logos {
    display: none;
  }

  body > .content .block.feedback .filledLogos {
    display: flex;
  }
}

@media only screen and (width <= 850px) {
  body .header .middle {
    text-align: center;
    flex-direction: column-reverse;
    align-items: center;
    padding: 72px 60px 32px;
  }

  body .header .middle .right {
    width: auto;
    margin-bottom: 32px;
  }

  body .header .middle .left {
    width: auto;
  }

  body .header .middle .left .buttons {
    padding: 32px 0;
  }

  body .header .middle .left .checks {
    justify-content: space-around;
  }

  body .header .middle .left .checks .check {
    margin-right: 0;
  }

  body .header .bottom {
    padding-left: 60px;
    padding-right: 60px;
  }

  body .content .block.feedback .filledLogos {
    justify-content: unset;
  }

  body .content .block.feedback .filledLogos > div {
    animation: 17s linear infinite slide;
  }

  body .content .block.feedback .filledLogos > div:nth-of-type(2) {
    display: flex;
  }

  body .content .block.feedback .filledLogos svg:first-of-type {
    margin-left: 48px;
  }
}

@media only screen and (width <= 775px) {
  body .header .middle .left .buttons {
    flex-direction: column;
    display: flex;
  }

  body .header .middle .left .buttons div:first-child {
    margin-bottom: 16px;
  }

  body .header .middle .left .buttons div a {
    width: 100%;
    margin-right: 0;
  }

  body .header .middle .left .checks {
    justify-content: space-around;
  }

  body .header .middle .left .checks .check {
    margin-right: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  body .header .middle .left .checks .check img {
    width: 12px;
    margin-left: 7px;
  }

  body .header .middle .left, body .header .middle .right {
    width: auto;
    max-width: none;
  }

  body .header .bottom {
    padding: 32px 16px;
  }

  body .content .block {
    margin: 120px 16px;
  }

  body .content .block.feedback .filledLogos {
    margin: 40px -16px 0;
  }

  body .content .block.products {
    margin-top: 32px;
    margin-bottom: 0;
  }

  body .content .block.products h1 {
    margin-bottom: 12px;
  }

  body .content .block.products .product {
    margin: 32px -12px 4px;
    padding: 32px 16px 48px;
  }

  body .content .block.products .product h2 {
    margin-bottom: 16px;
  }

  body .content .block.products .product:last-child {
    margin-top: 0;
  }

  body .content .block.products .product.first div:last-child {
    margin-top: 16px;
  }

  body .content .block.products h2 {
    align-items: center;
    display: flex;
  }

  body .content .block.redLine {
    margin: 64px 16px 48px 0;
  }

  body .content .center {
    text-align: inherit;
  }
}

@media only screen and (width <= 710px) {
  body .content .block {
    margin: 32px 16px;
  }

  body .content .block.products .product.split {
    flex-direction: column-reverse;
    align-items: center;
  }

  body .content .block.products .product.split > * {
    width: 100%;
  }

  body .content .block.products .product.second {
    flex-direction: column;
  }

  body .content .block.products .product.second .more {
    text-align: center;
    display: block !important;
  }

  body .content .block.products .product.second .more .os-icons {
    justify-content: center;
  }

  body .content .block.products .product.second .more > div:first-child {
    margin-bottom: 32px;
  }

  body .content .block .button {
    text-align: center;
    width: 100%;
  }

  body .content .block .bracket:after {
    display: none;
  }

  body .content .block.customerSuccess .steps div {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  body .content .block.customerSuccess .steps div span:first-child {
    margin-bottom: 8px;
    margin-right: 0 !important;
  }

  body .content .block.customerSuccess .steps.mobile {
    margin: 32px 0;
  }

  body .content .block.stories {
    margin-bottom: 48px;
  }

  body .content .block.stories .slider-wrapper.mobile .slider-track > [data-slider-item] {
    padding-bottom: 48px;
  }

  body .content .block.stories .slider-wrapper.mobile .quote-wrapper {
    gap: 16px;
  }

  body .content .block p {
    font-size: 16px;
    line-height: 24px;
  }

  body .content .block.GDPR {
    flex-direction: column;
    padding: 32px 16px 48px;
  }

  body .content .block.GDPR .left {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
  }

  body .content .block.GDPR .left div {
    width: 100%;
  }

  body .content .block.GDPR .left img {
    margin: 0 0 32px !important;
  }

  body .content .block.GDPR .right {
    width: 100%;
    margin-left: 0 !important;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
/*# sourceMappingURL=index.26a07fb4.css.map */
